<template>
  <div>
    <ul class="home__menu directory">
      <router-link to="/clients" tag="li">
        <div class="menu-icon menu-customers"></div>
        <p>{{$root.langText.directory.clients}}</p>
      </router-link>
      <router-link to="/counterparties" tag="li">
        <div class="menu-icon menu-counterparties"></div>
        <p>{{$root.langText.directory.counterparties}}</p>
      </router-link>
      <router-link to="/city" tag="li">
        <div class="menu-icon menu-directory"></div>
        <p>{{$root.langText.directory.city}}</p>
      </router-link>
      <router-link to="/drivers" tag="li">
        <div class="menu-icon menu-directory"></div>
        <p>{{$root.langText.directory.drivers}}</p>
      </router-link>
      <router-link to="/car" tag="li">
        <div class="menu-icon menu-directory"></div>
        <p>{{$root.langText.directory.car}}</p>
      </router-link>
      <router-link to="/trailers" tag="li">
        <div class="menu-icon menu-directory"></div>
        <p>{{$root.langText.directory.trailers}}</p>
      </router-link>
    </ul>
  </div>
</template>




<style lang="scss">
  @import '../assets/home.css';
  .directory.home__menu li{
    width: 50%;
    margin-bottom: 10px;
  }
  .directory.home__menu{
    padding: 30px 40px
  }
</style>



<script>
export default {
  
  data() {
    return {
      globalTitle: [
        {title: 'Довідник', info: ''}
      ],
    }
  },


  created() {
    this.globalTitle[0].title = this.$root.langText.menu.menuDirectory
    this.$root.title = this.globalTitle
  },

  metaInfo(){
    return {
      title: this.$title(this.$root.langText.menu.menuDirectory)
    }
  },
}
</script>